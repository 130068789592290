import axios from './axios'

export default async function sendApplication(fields) {
  try {
    let text = ''
    const formDataArray = Object.entries(fields)
    formDataArray.forEach(([key, value], i) => {
      if (value) {
        text += `${key}: ${value}${i < formDataArray.length - 1 ? '%0A' : ''}`
      }
    })

    const url = `https://sm-platon.by/api/?text=${text}`
    await axios.get(url)
  } catch (err) {
    console.error(err)
  }
}
